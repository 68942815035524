var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "지게차/운반구 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    icon: "help",
                                    color: "deep-purple-6",
                                    "text-color": "white",
                                    align: "center",
                                    round: "",
                                  },
                                },
                                [
                                  _c(
                                    "q-tooltip",
                                    {
                                      attrs: {
                                        anchor: "bottom left",
                                        self: "top left",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustomTop" },
                                        [_vm._v(" 설명 ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustom" },
                                        [
                                          _vm._v(
                                            " o 관리번호는 자동생성됩니다."
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " o 안전검사일은 유효검사기간에 맞춰 설정됩니다."
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.forkLiftData,
                                  mappingType: _vm.saveType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.forkLiftData.heqForkLiftId
                            ? _c("c-btn", {
                                attrs: {
                                  label: "LBLREMOVE",
                                  editable: _vm.editable,
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.remove },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.forkLiftData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "plantCd", $$v)
                            },
                            expression: "forkLiftData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "관리번호",
                            name: "managerNo",
                          },
                          model: {
                            value: _vm.forkLiftData.managerNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "managerNo", $$v)
                            },
                            expression: "forkLiftData.managerNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isOld,
                            codeGroupCd: "HEQ_TYPE_CD",
                            label: "지게차/운반구 유형",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            name: "facilityTypeCd",
                          },
                          on: { datachange: _vm.datachange },
                          model: {
                            value: _vm.forkLiftData.facilityTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "facilityTypeCd", $$v)
                            },
                            expression: "forkLiftData.facilityTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            plantCd: _vm.forkLiftData.plantCd,
                            multiple: "multiple",
                            label: "LBLPROCESS",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.forkLiftData.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "processCd", $$v)
                            },
                            expression: "forkLiftData.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            plantCd: _vm.forkLiftData.plantCd,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.forkLiftData.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "vendorCd", $$v)
                            },
                            expression: "forkLiftData.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "기기번호",
                            name: "deviceNo",
                          },
                          model: {
                            value: _vm.forkLiftData.deviceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "deviceNo", $$v)
                            },
                            expression: "forkLiftData.deviceNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "기계명",
                            name: "facilityName",
                          },
                          model: {
                            value: _vm.forkLiftData.facilityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "facilityName", $$v)
                            },
                            expression: "forkLiftData.facilityName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-dept-multi", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리부서",
                            name: "manageDeptCd",
                          },
                          model: {
                            value: _vm.forkLiftData.manageDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "manageDeptCd", $$v)
                            },
                            expression: "forkLiftData.manageDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            label: "임대/자가",
                            itemText: "codeName",
                            itemValue: "code",
                            type: "edit",
                            codeGroupCd: "HEQ_FORK_TYPE_CD",
                            name: "forkTypeCd",
                          },
                          model: {
                            value: _vm.forkLiftData.forkTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "forkTypeCd", $$v)
                            },
                            expression: "forkLiftData.forkTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  suffix: "년",
                                  type: "number",
                                  label: "안전검사유효기간",
                                  name: "checkValidPeriod",
                                },
                                on: { dataChange: _vm.datachangeDate },
                                model: {
                                  value: _vm.forkLiftData.checkValidPeriod,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.forkLiftData,
                                      "checkValidPeriod",
                                      $$v
                                    )
                                  },
                                  expression: "forkLiftData.checkValidPeriod",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  type: "date",
                                  label: "안전검사일",
                                  start: _vm.start,
                                  end: _vm.end,
                                  name: "safetyDate",
                                },
                                on: { datachange: _vm.datachangeDate },
                                model: {
                                  value: _vm.forkLiftData.safetyDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.forkLiftData,
                                      "safetyDate",
                                      $$v
                                    )
                                  },
                                  expression: "forkLiftData.safetyDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "q-chip",
                                {
                                  class:
                                    _vm.forkLiftData.validFlagName ===
                                    "유효기간 초과"
                                      ? "blinking"
                                      : null,
                                  attrs: {
                                    color: _vm.setTagColor(
                                      _vm.forkLiftData.validFlagName ===
                                        "유효기간 초과"
                                        ? "Y"
                                        : _vm.forkLiftData.validFlagName ===
                                          "입력중"
                                        ? "NE"
                                        : "orange"
                                    ),
                                    outline: "",
                                    square: "",
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm.forkLiftData.validFlagName ===
                                  "유효기간 초과"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "alarm",
                                          color: "red",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.forkLiftData.validFlagName === "입력중"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "create",
                                          color: "grey-5",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.setTagName(
                                          _vm.forkLiftData.validFlagName ===
                                            "유효기간 초과"
                                            ? "Y"
                                            : _vm.forkLiftData.validFlagName
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            type: "date",
                            editable: _vm.editable,
                            label: "반입일",
                            name: "carryinDate",
                          },
                          model: {
                            value: _vm.forkLiftData.carryinDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "carryinDate", $$v)
                            },
                            expression: "forkLiftData.carryinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            type: "date",
                            range: true,
                            label: "사용기간",
                            name: "useDate",
                          },
                          model: {
                            value: _vm.useDate,
                            callback: function ($$v) {
                              _vm.useDate = $$v
                            },
                            expression: "useDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            label: "운전자",
                            disabled: _vm.disabled,
                            plantCd: _vm.forkLiftData.plantCd,
                            isVendorOrg: true,
                            userName: "managerUserName",
                            name: "managerUserId",
                          },
                          model: {
                            value: _vm.forkLiftData.managerUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "managerUserId", $$v)
                            },
                            expression: "forkLiftData.managerUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "면허 일련번호",
                            name: "installLocation",
                          },
                          model: {
                            value: _vm.forkLiftData.installLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "installLocation", $$v)
                            },
                            expression: "forkLiftData.installLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            label: "교육 이수일",
                            name: "specifications",
                          },
                          model: {
                            value: _vm.forkLiftData.specifications,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "specifications", $$v)
                            },
                            expression: "forkLiftData.specifications",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비고",
                            name: "checkAction",
                          },
                          model: {
                            value: _vm.forkLiftData.checkAction,
                            callback: function ($$v) {
                              _vm.$set(_vm.forkLiftData, "checkAction", $$v)
                            },
                            expression: "forkLiftData.checkAction",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c("c-table", {
                    ref: "table",
                    attrs: {
                      title: "점검항목 목록",
                      columns: _vm.grid.columns,
                      gridHeight: _vm.grid.height,
                      merge: _vm.grid.merge,
                      data: _vm.forkLiftData.forkLiftHistoryResultList,
                      filtering: false,
                      isExcelDown: false,
                      columnSetting: false,
                      usePaging: false,
                      editable: _vm.editable,
                      rowKey: "heqForkLiftHistoryResultId",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "safetyMeasure"
                              ? [
                                  props.row["checkValue"] === "N"
                                    ? _c("c-text-column", {
                                        attrs: {
                                          editable: _vm.editable,
                                          props: props,
                                          col: col,
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: props.row["safetyMeasure"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.row,
                                              "safetyMeasure",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row['safetyMeasure']",
                                        },
                                      })
                                    : _c("span"),
                                ]
                              : col.name === "customCol"
                              ? [
                                  _c(_vm.imprComponent, {
                                    tag: "component",
                                    attrs: {
                                      col: col,
                                      props: props,
                                      inputEditable: _vm.editable && _vm.isOld,
                                      isImmShow: true,
                                      requestContentsCols:
                                        _vm.requestContentsCols,
                                      tableKey: "heqForkLiftHistoryResultId",
                                      ibmTaskTypeCd: "ITT0000200",
                                      ibmTaskUnderTypeCd: "ITTU000225",
                                    },
                                    on: { imprChange: _vm.imprChange },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c("br"),
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo,
                      editable: _vm.editable,
                      label: "관련 파일",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }