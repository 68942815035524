<template>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <q-form ref="editForm">
          <c-card
              title="지게차/운반구 기본정보"
              class="cardClassDetailForm"
          >
            <template slot="card-button">
              <q-btn-group outline>
                <q-btn
                  v-if="editable"
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      설명
                    </div>
                    <div class="tooltipCustom">
                    o 관리번호는 자동생성됩니다.<br>
                    o 안전검사일은 유효검사기간에 맞춰 설정됩니다.<br>
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="forkLiftData"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback"
                />
                <c-btn
                  v-if="forkLiftData.heqForkLiftId"
                  label="LBLREMOVE"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="forkLiftData.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="관리번호"
                  name="managerNo"
                  v-model="forkLiftData.managerNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  :editable="editable"
                  :disabled="isOld"
                  codeGroupCd="HEQ_TYPE_CD"
                  label="지게차/운반구 유형"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  name="facilityTypeCd"
                  v-model="forkLiftData.facilityTypeCd"
                  @datachange="datachange"
                >
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-process
                  :disabled="disabled"
                  :editable="editable"
                  :plantCd="forkLiftData.plantCd"
                  multiple="multiple"
                  label="LBLPROCESS"
                  name="processCd"
                  v-model="forkLiftData.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-vendor
                  required
                  :disabled="disabled"
                  :editable="editable"
                  :plantCd="forkLiftData.plantCd"
                  label="업체"
                  name="vendorCd"
                  v-model="forkLiftData.vendorCd"
                >
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  required
                  :editable="editable"
                  label="기기번호"
                  name="deviceNo"
                  v-model="forkLiftData.deviceNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="기계명"
                  name="facilityName"
                  v-model="forkLiftData.facilityName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-dept-multi
                  :editable="editable"
                  label="관리부서"
                  name="manageDeptCd"
                  v-model="forkLiftData.manageDeptCd"
                >
                </c-dept-multi>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :editable="editable"
                  label="임대/자가"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  codeGroupCd="HEQ_FORK_TYPE_CD"
                  name="forkTypeCd"
                  v-model="forkLiftData.forkTypeCd"
                >
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div class="row">
                  <div class="col-4">
                    <c-text
                      :editable="editable"
                      suffix="년"
                      type="number"
                      label="안전검사유효기간"
                      name="checkValidPeriod"
                      v-model="forkLiftData.checkValidPeriod"
                      @dataChange="datachangeDate"
                    >
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-datepicker
                      :editable="editable"
                      type="date"
                      label="안전검사일"
                      :start="start"
                      :end="end"
                      name="safetyDate"
                      v-model="forkLiftData.safetyDate"
                      @datachange="datachangeDate"
                    />
                  </div>
                  <div class="col-4">
                    <q-chip
                      :color="setTagColor(forkLiftData.validFlagName === '유효기간 초과' ? 'Y' :
                              forkLiftData.validFlagName === '입력중' ? 'NE' : 'orange')"
                      :class="forkLiftData.validFlagName ==='유효기간 초과' ? 'blinking' : null"
                      outline square
                      text-color="white">
                      <q-avatar v-if="forkLiftData.validFlagName==='유효기간 초과'" icon="alarm" color="red" text-color="white" />
                      <q-avatar v-if="forkLiftData.validFlagName==='입력중'" icon="create" color="grey-5" text-color="white" />
                      {{setTagName(forkLiftData.validFlagName === '유효기간 초과' ? 'Y' : forkLiftData.validFlagName)}}
                    </q-chip>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  type="date"
                  :editable="editable"
                  label="반입일"
                  name="carryinDate"
                  v-model="forkLiftData.carryinDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  type="date"
                  :range="true"
                  label="사용기간"
                  name="useDate"
                  v-model="useDate"
                />
              </div>
              <!-- <div class="col-3">
                <c-radio
                  :editable="editable"
                  :comboItems="flagItems"
                  label="안전검사 결과"
                  name="safetyResultFlag"
                  v-model="forkLiftData.safetyResultFlag"
                >
                </c-radio>
              </div> -->
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="실소유자 및 운전원"
                  name="operationAtor"
                  v-model="forkLiftData.operationAtor"
                >
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :editable="editable"
                  label="운전자"
                  :disabled="disabled"
                  :plantCd="forkLiftData.plantCd"
                  :isVendorOrg="true"
                  userName="managerUserName"
                  name="managerUserId"
                  v-model="forkLiftData.managerUserId"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  label="면허 일련번호"
                  name="installLocation"
                  v-model="forkLiftData.installLocation"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  label="교육 이수일"
                  name="specifications"
                  v-model="forkLiftData.specifications"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="비고"
                  name="checkAction"
                  v-model="forkLiftData.checkAction"
                >
                </c-text>
              </div>
            </template>
          </c-card>
          <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-table
                  ref="table"
                  title="안전대책 목록"
                  :columns="grid.columns"
                  :gridHeight="grid.height"
                  :data="forkLiftData.forkLiftSafetyMeasureList"
                  :filtering="false"
                  :columnSetting="false"
                  :isExcelDown="false"
                  :usePaging="false"
                  :editable="editable"
                  selection="multiple"
                  rowKey="heqForkLiftMeasureId"
                >
                <template slot="table-button">
                  <q-btn-group outline>
                    <c-btn
                      v-if="editable && !disabled"
                      :showLoading="false"
                      label="LBLADD"
                      icon="add"
                      @btnClicked="addRisk"
                    />
                    <c-btn
                      v-if="
                        editable &&
                        !disabled &&
                        forkLiftData.forkLiftSafetyMeasureList.length > 0
                      "
                      :showLoading="false"
                      label="LBLEXCEPT"
                      icon="remove"
                      @btnClicked="removeRisk"
                    />
                  </q-btn-group>
                </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <br>
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="관련 파일">
                </c-upload>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="table"
              title="점검 이력 목록"
              tableId="forkLiftHis"
              :columns="grid2.columns"
              :gridHeight="grid2.height"
              :data="forkLiftData.forkLiftHistoryList"
              :usePaging="false"
              :checkClickFlag="false"
              :isExcelDown="false"
              :merge="grid.merge"
              selection="multiple"
              rowKey="heqForkLiftHistoryId"
              @linkClick="linkClick"
            >
              <template slot="table-button">
                <font v-if="!isOld" color="#C10015">
                  {{'※ 신규등록시 기본정보 저장 후 점검할 수 있습니다.'}}  
                </font>  
                <q-btn-group outline >
                  <c-btn v-if="editable && isOld" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="linkClick" />
                  <c-btn v-if="editable && isOld" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
                </q-btn-group>
              </template>
            </c-table>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="table"
              title="점검항목 목록"
              :columns="grid.columns"
              :gridHeight="grid.height"
              :merge="grid.merge"
              :data="forkLiftData.forkLiftHistoryResultList"
              :filtering="false"
              :isExcelDown="false"
              :columnSetting="false"
              :usePaging="false"
              :editable="editable"
              rowKey="heqForkLiftHistoryResultId"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.name ==='safetyMeasure'">
                  <c-text-column
                    v-if="props.row['checkValue'] === 'N'"
                    :editable="editable"
                    :props="props"
                    :col="col"
                    :disabled="disabled"
                    v-model="props.row['safetyMeasure']"
                  />
                  <span v-else>
                  </span>
                </template>
                <template v-else-if="col.name==='customCol'">
                  <component
                    :is="imprComponent"
                    :col="col"
                    :props="props"
                    :inputEditable="editable&&isOld"
                    :isImmShow="true"
                    :requestContentsCols="requestContentsCols"
                    tableKey="heqForkLiftHistoryResultId"
                    ibmTaskTypeCd="ITT0000200"
                    ibmTaskUnderTypeCd="ITTU000225"
                    @imprChange="imprChange"
                  />
                </template>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <br>
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable"
              label="관련 파일">
            </c-upload>
          </div>
        </q-form>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "forkLiftInfo",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heqForkLiftId: '',
      }),
    },
  },
  data() {
    return {
      forkLiftData: {
        heqForkLiftId: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        facilityTypeId: '',
        facilityTypeCd: null,
        facilityTypeName: '',
        facilityName: '',
        deviceNo: '',
        installLocation: '',
        carryinDate: '',
        useStartYmd: '',
        useEndYmd: '',
        safetyDate: '',
        safetyResultFlag: 'Y',
        checkAction: '',
        managerNo: '',
        managerUserId: '',
        managerUserName: '',
        regUserId: '',
        chgUserId: '',
        validFlagName: '일 남음',
        specifications: '',
        operationAtor: '',
        checkValidPeriod: '',
        forkTypeCd: null,
        manageDeptCd: '',

        forkLiftHistoryResultList: [],
        // forkLiftSafetyMeasureList: [],
        // deleteForkLiftSafetyMeasureList: [],
        // forkLiftHistoryList: [],
        // deleteForkLiftHistoryList: [],
      },
      useDate: [],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'FORKLIFT_INFO',
        taskKey: '',
      },
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: "transactionConfig.sop.heq.forkLift.insert.url",
      saveType: "POST",
      isSave: false,
      checkUrl: "",
      insertUrl: "",
      disabled: false,
      colorItems: [
        { colorCode: 'Y', colorName: '유효기간 초과', colorClass: 'red' },
        { colorCode: 'NE', colorName: '입력중', colorClass: 'grey-5' },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'typeName' },
        ],
        columns: [
          {
            name: "typeName",
            field: "typeName",
            label: "구분",
            style: 'width:10%',
            align: "center",
            sortable: false,
          },
          {
            name: "resultName",
            field: "resultName",
            label: "점검항목",
            style: 'width:50%',
            align: "left",
            type: 'html',
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "checkValue",
            field: "checkValue",
            label: "점검결과",
            style: 'width:10%',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '적정' },
              { code: 'N', codeName: '부적정' },
              { code: 'NE', codeName: '해당없음' },
            ]
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:30%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        height: '700px',
        data: []
      },
      grid2: {
        columns: [
          {
            name: "inspectionUserName",
            field: "inspectionUserName",
            label: "점검자",
            style: 'width:120px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionDate",
            field: "inspectionDate",
            label: "점검일",
            style: 'width:120px',
            align: "center",
            type: 'link',
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            label: "특이사항",
            style: 'width:350px',
            align: "left",
            sortable: false,
          },
        ],
        height: '280px',
        data: [],
      },
      deleteUrl: "",
      deleteHistoryUrl: '',
      updateUrl: "",
      getUrl: "",
      stdUrl: '',
      flagItems: [
        { code: "N", codeName: "통과" },
        { code: "Y", codeName: "미통과" },
      ],
      start: '',
      end: '',
    };
  },
    beforeCreate() {},
    created() {},
    beforeMount() {
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {},
    computed: {
      isOld() {
        return Boolean(this.popupParam.heqForkLiftId)
      },
      // 개선요청 내용 전달
      requestContentsCols() {
        return ['resultName'];
      },
      // 개선요청 Component
      imprComponent() {
        return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      },
    },
    watch: {
    },
    methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.getUrl = selectConfig.sop.heq.forkLift.get.url;
      this.checkUrl = selectConfig.sop.heq.forkLift.check.url;
      this.insertUrl = transactionConfig.sop.heq.forkLift.insert.url;
      this.updateUrl = transactionConfig.sop.heq.forkLift.update.url;
      this.deleteUrl = transactionConfig.sop.heq.forkLift.delete.url;
      this.deleteHistoryUrl = transactionConfig.sop.heq.forkLift.result.delete.url;
      this.stdUrl = selectConfig.sop.heq.inspectitem.list.url
      this.getDetail();

    },
    getDetail() {
      if (this.popupParam.heqForkLiftId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heqForkLiftId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.forkLiftData = this.$_.clone(_result.data);
          this.$emit('setRegInfo', _result.data)
          if (_result.data.useStartYmd && _result.data.useEndYmd) {
            this.useDate = [this.forkLiftData.useStartYmd, this.forkLiftData.useEndYmd]
          }
          this.datachangeDate();
          this.$set(this.attachInfo, 'taskKey', this.popupParam.heqForkLiftId)
        },);
      }
    },
    saveInfo() {
      if (this.popupParam.heqForkLiftId) {
          this.saveUrl = this.updateUrl;
          this.saveType = "PUT";
      } else {
          this.saveUrl = this.insertUrl;
          this.saveType = "POST";
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.type = "GET";
          this.$http.param = {
            heqForkLiftId: this.forkLiftData.heqForkLiftId,
            plantCd: this.forkLiftData.plantCd,
            deviceNo: this.forkLiftData.deviceNo,
          };
          this.$http.request((_result) => {
            if (_result.data.deviceNo > 0) {
              window.getApp.$emit("ALERT", {
                title: "LBLGUIDE", // 안내
                message:
                    "사업장 내 이미 존재하는 기기번호입니다.", // 사업장 내 이미 존재하는 기기번호입니다
                type: "warning", // success / info / warning / error
              });
              return;
            } else {
              window.getApp.$emit("CONFIRM", {
                title: "LBLCONFIRM",
                message: "MSGSAVE",
                // TODO : 필요시 추가하세요.
                type: "info", // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.forkLiftData.regUserId = this.$store.getters.user.userId;
                  this.forkLiftData.chgUserId = this.$store.getters.user.userId;
                  if (this.useDate) {
                    this.forkLiftData.useStartYmd = this.useDate[0]
                    this.forkLiftData.useEndYmd = this.useDate[1]
                  }
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {},
              });
            }
          });
        } else {
            window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.popupParam.heqForkLiftId = _result.data
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())

      this.getDetail();
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.forkLiftData.heqForkLiftId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode : data })
        return color ? color.colorClass : 'blue';
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode: data })
        return color ? color.colorName : data;
      }
    },
    datachangeDate() {
      // 유효기간 
      this.end = this.$comm.getToday();
      this.start = this.$comm.getCalculatedDate(
            this.$comm.getToday(),
            '-' + this.forkLiftData.checkValidPeriod + 'y',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          )
      this.start = this.$comm.getCalculatedDate(
            this.start,
            '1d',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          )
      if (!this.forkLiftData.safetyDate) {
        this.forkLiftData.validFlagName = '입력중'
      } else {
        var stDate = new Date(this.$comm.getCalculatedDate(
            this.forkLiftData.safetyDate,
            this.forkLiftData.checkValidPeriod + 'y',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          ));
        var endDate = new Date(this.end);

        var btMs = stDate.getTime() - endDate.getTime();
        var btDay = btMs / (1000*60*60*24);

        if (btDay < 0) {
          this.forkLiftData.validFlagName = '유효기간 초과'
        } else {
          this.forkLiftData.validFlagName = btDay + '일 남음'
        }
      }
    },
    datachange() {
      this.forkLiftData.forkLiftHistoryResultList = [];
      if (this.forkLiftData.facilityTypeCd) {
        this.$http.url = this.stdUrl;
        this.$http.type = 'GET';
        this.$http.param = {heqTypeCd: this.forkLiftData.facilityTypeCd};
        this.$http.request((_result) => {
          if (_result.data) {
            this.$_.forEach(_result.data, _item => {
              this.forkLiftData.forkLiftHistoryResultList.push({
                heqForkLiftId: this.popupParam.heqForkLiftId,
                heqForkLiftHistoryResultId: uid(),
                typeName: _item.typeName,
                resultName: _item.inspectItem,
                checkValue: 'NE',
                safetyMeasure: '',
                editFlag: 'C',
              })
            })
          }
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    // addRisk() {
    //   this.forkLiftData.forkLiftSafetyMeasureList.splice(0, 0, {
    //     heqForkLiftId: this.forkLiftData.heqForkLiftId,
    //     heqForkLiftMeasureId: uid(),
    //     riskFactors: '',
    //     safetyMeasure: '',
    //     regUserId: this.$store.getters.user.userId,
    //     editFlag: "C",
    //   });
    // },
    // removeRisk() {
    //   let selectData = this.$refs["table"].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit("ALERT", {
    //       title: "LBLGUIDE", // 안내
    //       message: "MSGNOITEMS", // 선택된 항목이 없습니다.
    //       type: "warning", // success / info / warning / error
    //     });
    //   } else {
    //     this.$_.forEach(selectData, (item) => {
    //       if (!this.forkLiftData.deleteForkLiftSafetyMeasureList) {
    //         this.forkLiftData.deleteForkLiftSafetyMeasureList = [];
    //       }
    //       if (
    //         this.$_.findIndex(this.forkLiftData.deleteForkLiftSafetyMeasureList, {
    //           heqForkLiftMeasureId: item.heqForkLiftMeasureId,
    //         }) === -1 &&
    //         item.editFlag !== "C"
    //       ) {
    //         this.forkLiftData.deleteForkLiftSafetyMeasureList.push(item);
    //       }
    //       this.forkLiftData.forkLiftSafetyMeasureList = this.$_.reject(
    //         this.forkLiftData.forkLiftSafetyMeasureList,
    //         item
    //       );
    //     });
    //   }
    // },
    // linkClick(result) {
    //   if (!this.forkLiftData.facilityTypeCd) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //     return;
    //   }
    //   this.popupOptions.title = '지게차/운반구 이력 상세';
    //   this.popupOptions.target = () => import(`${'./forkLiftHistoryResult.vue'}`);
    //   this.popupOptions.param = {
    //     heqForkLiftHistoryId: result ? result.heqForkLiftHistoryId : '', 
    //     facilityTypeCd: this.forkLiftData.facilityTypeCd,
    //     facilityTypeName: this.forkLiftData.facilityTypeName,
    //     facilityName: this.forkLiftData.facilityName,
    //     heqForkLiftId: this.popupParam.heqForkLiftId,
    //     checkValidPeriod: this.forkLiftData.checkValidPeriod,
    //     vendorCd: this.forkLiftData.vendorCd,
    //     plantCd: this.forkLiftData.plantCd
    //   } 
    //   this.popupOptions.visible = true;
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.width = '85%';
    //   this.popupOptions.closeCallback = this.closeItemPopup;
    // },
    // closeItemPopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   this.getDetail();
    // },
    // removeItem() {
    //   let selectData = this.$refs['table'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: 'LBLGUIDE', // 안내
    //       message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //     title: 'LBLCONFIRM',
    //     message: 'MSGREMOVE', // 삭제하시겠습니까?,
    //     // TODO : 필요시 추가하세요.
    //     type: 'warning', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.$http.url = this.deleteHistoryUrl;
    //       this.$http.type = 'DELETE';
    //       this.$http.param = {
    //         data: Object.values(selectData)
    //       }
    //       this.$http.request(() => {
    //         this.$_.forEach(selectData, item => {
    //           this.forkLiftData.forkLiftHistoryList = this.$_.reject(this.forkLiftData.forkLiftHistoryList, { hhmHazardousMachineryId: item.hhmHazardousMachineryId })
    //         })
    //         window.getApp.$emit('APP_REQUEST_SUCCESS');
    //         this.getDetail();
    //       },);
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //     },
    //   });
    //   }
    // },
  },
};
</script>

<style>

.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>